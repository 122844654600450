import styled from 'styled-components'

export default styled.div`
  width: 100%;
  display: flex;
  height: 295px;
  margin-bottom: 20px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  .map-camera-container {
    width: 400px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    &:hover {
      cursor: default;
    }
  }

  .camera-detail-container {
    display: flex;
    flex: 1;
    width: 100%;
    .area-detail-card {
      width: 210px;
      display: flex;
      .detail-content-container {
        padding-left: 20px;
        padding-right: 10px;
        background: white;
        height: calc(100% - 40px);
        .detail-row-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 15px 20px;
            height: 84px;
            .title {
              font-family: 'Prompt', sans-serif;
              font-weight: bold;
              font-size: 12px;
              line-height: 1;
              text-transform: uppercase;
              color: ${(props) => props.theme.darkBackground};
              margin-bottom: 6px;
              &.subheader-long {
                font-size: 11px;
              }
            }
            .value {
              font-family: 'Prompt', sans-serif;
              font-weight: bold;
              font-size: 26px;
              font-weight: bold;
              color: ${(props) => props.theme.darkBackground};
              max-width: 180px;
              &.numberbox {
                max-width: 90px;
              }
              &:hover {
                .tooltip-wrapper {
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    .graph-detail-card {
      width: 100%;
      flex: 1;
      .graph-content-container {
        background: white;
        padding-left: 20px;
        height: calc(100% - 40px);
      }
    }
  }
`
