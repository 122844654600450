/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import MapCameraComponent from '../MapCameraComponent/Loadable'
import DivideLine from '../DivideLine/Loadable'
import NumberBox from '../NumberBox/Loadable'
import BarChartComponent from '../BarChartComponent/Loadable'
import SkeletonLoading from '../SkeletonLoading/Loadable'
import CardWrapper from '../CardWrapper/Loadable'

import AreaDetailCardStyled from './styledComponent'
import MESSAGE from './message'

class AreaDetailCard extends React.PureComponent {
  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  getDetailBox() {
    let subheaderClassName = ''
    if (this.getMessage('total_violation_subheader').length > 20) {
      subheaderClassName = ' subheader-long'
    }

    return (
      <CardWrapper cardTitle={this.getMessage('detail_header')} className="area-detail-card">
        <div className="detail-content-container">
          <div className="detail-row-container">
            <div className="content text-style">
              <div className="title">{this.getMessage('area_subheader')}</div>
              <Tooltip title={this.props.detail.areaName}>
                <div className="value prevent-text-overflow">{this.props.detail.areaName}</div>
              </Tooltip>
            </div>
          </div>
          <DivideLine className="detail-divide" />
          <div className="detail-row-container">
            <div className="content text-style">
              <div className={`title ${subheaderClassName}`}>{this.getMessage('total_violation_subheader')}</div>
            </div>
            <NumberBox text={this.props.detail.totalViolationCount} />
          </div>
          <DivideLine className="detail-divide" />
          <div className="detail-row-container">
            <div className="content text-style">
              <div className={`title ${subheaderClassName}`}>{this.getMessage('most_violation_subheader')}</div>
              <Tooltip title={this.props.detail.mostViolationAreaName}>
                <div className="value numberbox prevent-text-overflow">{this.props.detail.mostViolationAreaName}</div>
              </Tooltip>
            </div>
            <NumberBox text={this.props.detail.mostViolationCount} />
          </div>
        </div>
      </CardWrapper>
    )
  }

  getGraphBox() {
    const graphComponent = (
      <BarChartComponent
        listBars={this.props.listGraphDetail}
        data={this.props.graphData}
        xLabel={this.getMessage('time_label')}
        yLabel={this.getMessage('count_label')}
      />
    )

    return (
      <CardWrapper cardTitle={this.getMessage('violation_count_header')} className="graph-detail-card">
        <div className="graph-content-container">{this.props.graphData.length > 0 ? graphComponent : null}</div>
      </CardWrapper>
    )
  }

  getAreaDetailCard() {
    return (
      <>
        <div className="map-camera-container">
          <MapCameraComponent listCamera={this.props.listCamera} onCameraClicked={(cameraData) => this.props.onCameraClicked(cameraData)} />
        </div>
        <div className="camera-detail-container">
          {this.getDetailBox()}
          {this.getGraphBox()}
        </div>
      </>
    )
  }

  getContent() {
    const content = this.props.isLoading ? <SkeletonLoading /> : this.getAreaDetailCard()
    return content
  }

  render() {
    return <AreaDetailCardStyled>{this.getContent()}</AreaDetailCardStyled>
  }
}

AreaDetailCard.defaultProps = {
  language: 'EN',
  isLoading: false
}

AreaDetailCard.propTypes = {
  listCamera: PropTypes.arrayOf(
    PropTypes.shape({
      camera_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      location: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired
      }).isRequired,
      camera_status: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  detail: PropTypes.shape({
    areaName: PropTypes.string,
    totalViolationCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mostViolationAreaName: PropTypes.string,
    mostViolationCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  graphData: PropTypes.array.isRequired,
  listGraphDetail: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  isLoading: PropTypes.bool,
  language: PropTypes.string,
  onCameraClicked: PropTypes.func
}

export default AreaDetailCard
