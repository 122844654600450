const MESSAGE = {
  EN: {
    detail_header: 'DETAILS',
    violation_count_header: 'VIOLATION COUNT',
    area_subheader: 'AREA',
    total_violation_subheader: 'TOTAL VIOLATION',
    most_violation_subheader: 'MOST VIOLATION',
    time_label: 'Time',
    count_label: 'Count'
  },
  TH: {
    detail_header: 'รายละเอียด',
    violation_count_header: 'จำนวนครั้งในการฝ่าฝืน',
    area_subheader: 'พื้นที่',
    total_violation_subheader: 'จำนวนการฝ่าฝืนทั้งหมด (ครั้ง)',
    most_violation_subheader: 'มีการฝ่าฝืนมากที่สุด',
    time_label: 'เวลา',
    count_label: 'จำนวน'
  }
}
export default MESSAGE
